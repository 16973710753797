import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { cookieStorage } from "../../utils/cookieStorage";
type LoggedInAsStateType = {
  token: string | null;
  isAuthenticating: boolean;
  impersonatedBy: string | null;
};

const initialState: LoggedInAsStateType = {
  token: cookieStorage.getItemSync("token") || null,
  impersonatedBy: cookieStorage.getItemSync("impersonatedBy") || null,
  // useBetaObserver change this value to relevant state once check for relogin passed
  isAuthenticating: true,
};

export const userStatusSlice = createSlice({
  name: "loggedInAs",
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      if (payload) {
        cookieStorage.setItemSync("token", payload);
      } else {
        cookieStorage.removeItemSync("token");
      }
      state.token = payload;
    },
    setImpersonatedBy: (state, { payload }: PayloadAction<string | null>) => {
      if (payload) {
        cookieStorage.setItemSync("impersonatedBy", payload);
      } else {
        cookieStorage.removeItemSync("impersonatedBy");
      }
      state.impersonatedBy = payload;
    },
    setIsAuthenticating: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticating = payload;
    },
  },
});

export const loggedInAsReducer = userStatusSlice.reducer;

const selectToken = (state: any): string | null => state.loggedInAs.token;

const selectImpersonatedBy = (state: any): string | null =>
  state.loggedInAs.impersonatedBy;

const selectIsAuthenticating = (state: any): boolean =>
  state.loggedInAs.isAuthenticating;

export const useLoggedInAs = () => {
  const dispatch = useDispatch();
  const actions = userStatusSlice.actions;

  const token = useSelector(selectToken);

  const setToken = (val: string | null) => dispatch(actions.setToken(val));

  const setImpersonatedBy = (val: string | null) =>
    dispatch(actions.setImpersonatedBy(val));

  const impersonatedBy = useSelector(selectImpersonatedBy);

  const isAdminLoggedInAsUser = !!impersonatedBy;

  const setIsAuthenticating = (val: boolean) =>
    dispatch(actions.setIsAuthenticating(val));

  const isAuthenticating = useSelector(selectIsAuthenticating);

  const isAuthenticated = !!useSelector(selectToken);

  return {
    token,
    isAuthenticated,
    setToken,
    impersonatedBy,
    setImpersonatedBy,
    isAuthenticating,
    setIsAuthenticating,
    isAdminLoggedInAsUser,
  };
};
