import chainGet from "src/services/network/chainGet";
import { useCompanySelection } from "src/store/companySelectionState";

const useIsSubAccountType = () => {
  const { activeCompanyObject } = useCompanySelection();
  const accountType = chainGet(activeCompanyObject?.type, "");

  return "Sub-Account" === accountType;
};

export default useIsSubAccountType;
