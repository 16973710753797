import React from "react";
import { BiError } from "react-icons/bi";
import { Text, Flex, Icon } from "@chakra-ui/react";
import { cookieStorage } from "src/utils/cookieStorage";
import store from "src/store";
import { appVersionSlice } from "src/store/appVersion";
import fetchAppMeta from "src/services/data/fetchAppMeta";

/* eslint-disable react/prop-types */
class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, message: null, stack: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
    if (error?.message) {
      const message = error?.message;
      const stack = error?.stack;
      this.setState({ message, stack });
    }

    if (
      error?.name === "ChunkLoadError" ||
      error?.message === "ChunkLoadError"
    ) {
      fetchAppMeta().then((appMeta) => {
        if (cookieStorage.getItemSync("appVersion") !== appMeta?.version) {
          store.dispatch(
            appVersionSlice.actions.setIsAppVersionUpdateModalOpen(true)
          );
        }
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex justify="center" align="center" h="50vh" flexDir="column">
          <Icon as={BiError} fontSize="50px" />
          <Text color="red" fontSize="20px" fontWeight="bold">
            Something went wrong...
          </Text>
          <Flex
            mt={2}
            border="1px dashed red"
            p={4}
            maxW={{ base: "100%", xl: "70%" }}
            flexDir="column"
          >
            {this.state.message && (
              <Text color="red.500">{this.state.message}</Text>
            )}
            ---
            {this.state.stack && (
              <Text noOfLines={5} color="gray.600">
                {this.state.stack}
              </Text>
            )}
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
