import { useToast } from "@chakra-ui/react";
import { useCompanySelection } from "src/store/companySelectionState";
import { useMutation } from "react-query";
import type { UseMutationResult } from "react-query";
import axios from "src/services/network/axios";
import type { AxiosError, AxiosResponse } from "axios";
import getCallStatusSchedulersKeys from "src/hooks/queries/account/useCallStatusSchedulersQuery/getCallStatusSchedulersKeys";
import { useQueryClient } from "react-query";
import { getCallStatusTemplateKeys } from "src/hooks/queries/settings/useContactCallStatusTemplates";
import { useStatusTemplateFilterContext } from "src/contexts/StatusTemplateFilterContext";
import { StringOrNull } from "src/types";

// SAMPLE PAYLOAD*
// const foo = {
//   takeCalls: true,
//   doNotConnect: "+790111111111",
//   connectOnly: "+390111111111",
//   unavailabilityExplanation: "Unavailable",
//   unavailabilityExplanationOther: "One more",
//   statusNote: "And ofcourse status, how without it",
//   name: "My template",
// };

// SAMPLE RESPONSE
// const foo = {
//   unavailabilityExplanation: "Unavailable",
//   takeCalls: "false",
//   statusNote: "NOTENOTENOTE",
//   id: "a0B8N000000Oxh4UAC",
//   connectOnly: "",
//   name: "TESTHEHEHE",
// };

export type AddCallStatusTemplateType = {
  takeCalls: boolean;
  doNotConnect?: string;
  connectOnly?: string;
  unavailabilityExplanation?: string;
  unavailabilityExplanationOther?: string;
  statusNote?: string;
  name: string;
};

export type AddCallStatusTemplateResponseType = {
  id: string;
  takeCalls: boolean;
  doNotConnect?: string;
  connectOnly?: string;
  unavailabilityExplanation: StringOrNull;
  unavailabilityExplanationOther: StringOrNull;
  statusNote?: StringOrNull;
  name: string;
};

type AddTemplateAxiosResponse =
  AxiosResponse<AddCallStatusTemplateResponseType>;

const addCallStatusTemplate =
  (companyId: string, contactId: string) =>
  async (
    payload: AddCallStatusTemplateType
  ): Promise<AddTemplateAxiosResponse> => {
    const body = payload;
    // {{baseUrl}}/companies/:companyId/contacts/:contactId/callStatusTemplates
    const response = await axios.post(
      `/companies/${companyId}/contacts/${contactId}/callStatusTemplates`,
      body
    );

    return response;
  };

type ParamType = {
  onSuccess?: (arg: any) => void;
};

const useAddCallStatusTemplate = (options?: ParamType) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const filtersContext = useStatusTemplateFilterContext();
  const { queryFilter, isTakingCallsFilter, sortState } = filtersContext;

  const [sortOption] = sortState;
  const sortBy = sortOption.sortBy;
  const sortType = sortOption.sortType;

  const companySelection = useCompanySelection();
  const companyId = companySelection!.activeCompany as string;
  const contactId = companySelection!.activeCompanyObject?.contactId as string;

  // INVALIDATE STATUS TEMPLATE KEYS TODO*****
  const contactCallStatusTemplatesKeys = getCallStatusTemplateKeys({
    companyId,
    contactId,
    sort: {
      sortBy,
      sortType,
    },
    filters: {
      queryFilter,
      isTakingCallsFilter,
    },
  });

  const mutation = useMutation(addCallStatusTemplate(companyId, contactId), {
    onSuccess(response) {
      queryClient.invalidateQueries(contactCallStatusTemplatesKeys);
      options?.onSuccess && options.onSuccess(response);
      toast({
        title: "Status Template Create Success",
        description: "Status Template was created successfully!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError(err: AxiosError) {
      const errMessage = err?.response?.data?.message;
      toast({
        title: "Status Template Create Failed",
        description: errMessage || "Fail to create Status Template.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    },
  });

  return mutation;
};

export default useAddCallStatusTemplate;
