import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../services/data/status";
import { defaultTo } from "ramda";
import { statusLabels } from "src/components/shared/navigationMenu/Header/StatusMenu";

export type StatusLabelKeysType = keyof typeof statusLabels;

type UserStatusStateType = {
  status: StatusLabelKeysType;
  statusExpireAtUTC: string | null;
  transferTo: string | null;
  connectOnly: string | null;
  doNotConnect: string | null;
  currentUnavailabilityExplanation: string | null;
  currentUnavailabilityExplanationOther: string | null;
  currentStatusNote: string | null;
  callStatusTemplateId: string | null;
  isOpen: number;
  overrideSubscriptionPagePermissions?: string | null;
};

const initialState: UserStatusStateType = {
  status: "Online",
  statusExpireAtUTC: null,
  transferTo: null,
  connectOnly: null,
  doNotConnect: null,
  currentUnavailabilityExplanation: null,
  currentUnavailabilityExplanationOther: null,
  currentStatusNote: null,
  callStatusTemplateId: null,
  isOpen: 0,
  overrideSubscriptionPagePermissions: undefined,
};

const getStatusFromApi = (takeCalls: string) => {
  let statusFromAPI = "Offline";

  if (takeCalls === "Yes") {
    statusFromAPI = "Online";
  }

  if (takeCalls === "No") {
    statusFromAPI = "Offline";
  }

  if (takeCalls === "Emergency Calls Only") {
    statusFromAPI = "Emergency Calls Only";
  }

  return statusFromAPI;
};

export const userStatusSlice = createSlice({
  name: "userStatus",
  initialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<StatusLabelKeysType>) => {
      state.status = payload;
    },
    setStatusExpireAtUTC: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.statusExpireAtUTC = payload;
    },
    setTransferTo: (state, { payload }: PayloadAction<string | null>) => {
      state.transferTo = payload;
    },
    // Advanced
    setConnectOnly: (state, { payload }: PayloadAction<string | null>) => {
      state.connectOnly = payload;
    },
    setDoNotConnect: (state, { payload }: PayloadAction<string | null>) => {
      state.doNotConnect = payload;
    },
    setCurrentUnavailabilityExplanation: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.currentUnavailabilityExplanation = payload;
    },
    setCurrentUnavailabilityExplanationOther: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.currentUnavailabilityExplanationOther = payload;
    },
    setCurrentStatusNote: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.currentStatusNote = payload;
    },
    setCallStatusTemplateId: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.callStatusTemplateId = payload;
    },
    setIsOpen: (state, { payload }: PayloadAction<number>) => {
      state.isOpen = payload;
    },
    setOverrideSubscriptionPagePermissions: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.overrideSubscriptionPagePermissions = payload;
    },
  },
});

export const userStatusReducer = userStatusSlice.reducer;

const selectStatus = (state: any): StatusLabelKeysType =>
  state.userStatus.status;

const selectStatusExpireAtUTC = (state: any) =>
  state.userStatus.statusExpireAtUTC;

const selectTransferTo = (state: any) => state.userStatus.transferTo;

const selectConnectOnly = (state: any) => state.userStatus.connectOnly;
const selectDoNotConnect = (state: any) => state.userStatus.doNotConnect;
const selectCurrentUnavailabilityExplanation = (state: any) =>
  state.userStatus.currentUnavailabilityExplanation;
const selectCurrentUnavailabilityExplanationOther = (state: any) =>
  state.userStatus.currentUnavailabilityExplanationOther;
const selectCurrentStatusNote = (state: any) =>
  state.userStatus.currentStatusNote;
const selectCallStatusTemplateId = (state: any) =>
  state.userStatus.callStatusTemplateId;
const selectIsOpen = (state: any) => state.userStatus.isOpen;
const selectOverrideSubscriptionPagePermissions = (state: any) =>
  state.userStatus.overrideSubscriptionPagePermissions;

export const useUserStatus = () => {
  const dispatch = useDispatch();
  const actions = userStatusSlice.actions;

  // General
  const setStatus = (status: StatusLabelKeysType) =>
    dispatch(actions.setStatus(status));

  const setStatusExpireAtUTC = (statusExpireAtUTC: string | null) =>
    dispatch(actions.setStatusExpireAtUTC(statusExpireAtUTC));

  const setTransferTo = (status: string | null) =>
    dispatch(actions.setTransferTo(status));

  const status = useSelector(selectStatus);
  const statusExpireAtUTC: string | null = useSelector(selectStatusExpireAtUTC);
  const transferTo: string | null = useSelector(selectTransferTo);

  // Advanced
  const setConnectOnly = (val: string | null) =>
    dispatch(actions.setConnectOnly(val));
  const setDoNotConnect = (val: string | null) =>
    dispatch(actions.setDoNotConnect(val));
  const setCurrentUnavailabilityExplanation = (val: string | null) =>
    dispatch(actions.setCurrentUnavailabilityExplanation(val));
  const setCurrentUnavailabilityExplanationOther = (val: string | null) =>
    dispatch(actions.setCurrentUnavailabilityExplanationOther(val));
  const setCurrentStatusNote = (val: string | null) =>
    dispatch(actions.setCurrentStatusNote(val));
  const setCallStatusTemplateId = (val: string | null) =>
    dispatch(actions.setCallStatusTemplateId(val));
  const setOverrideSubscriptionPagePermissions = (val: string | null) =>
    dispatch(actions.setOverrideSubscriptionPagePermissions(val));
  const setIsOpen = (val: number) => dispatch(actions.setIsOpen(val));

  const connectOnly: string | null = useSelector(selectConnectOnly);
  const doNotConnect: string | null = useSelector(selectDoNotConnect);
  const currentUnavailabilityExplanation: string | null = useSelector(
    selectCurrentUnavailabilityExplanation
  );
  const currentUnavailabilityExplanationOther: string | null = useSelector(
    selectCurrentUnavailabilityExplanationOther
  );
  const currentStatusNote: string | null = useSelector(selectCurrentStatusNote);
  const callStatusTemplateId: string | null = useSelector(
    selectCallStatusTemplateId
  );

  const overrideSubscriptionPagePermissions: string | null = useSelector(
    selectOverrideSubscriptionPagePermissions
  );

  // Popover
  const isOpenNumber: number = useSelector(selectIsOpen);

  const isOpen: boolean = isOpenNumber !== 0;

  const isOpenExternal = isOpenNumber === 2;

  const onClose = () => setIsOpen(0);

  const onOpen = () => setIsOpen(1);

  const onOpenExternal = () => setIsOpen(2);

  // Fill
  const parseFromContact = (contact: any) => {
    const takeCalls = defaultTo("No", contact.takeCalls);

    const statusFromApi = getStatusFromApi(takeCalls);

    setTransferTo(contact.firstTransferPhone || null);
    setStatus(statusFromApi as StatusLabelKeysType);
    setStatusExpireAtUTC(contact.statusExpirationDate);
    if (contact.statusExpirationDate) {
      setTransferTo(contact.firstTransferPhone || null);
    }
    setConnectOnly(contact.connectOnly || null);
    setDoNotConnect(contact.doNotConnect || null);
    setCurrentUnavailabilityExplanation(
      contact.currentUnavailabilityExplanation || null
    );
    setCurrentUnavailabilityExplanationOther(
      contact.currentUnavailabilityExplanationOther || null
    );
    setCurrentStatusNote(contact.currentStatusNote || null);
    setOverrideSubscriptionPagePermissions(
      contact.overrideSubscriptionPagePermissions || null
    );
    setCallStatusTemplateId(contact.callStatusTemplateId || null);
  };

  const fetch = async (companyId: string, contactId: string, force = true) => {
    const contactRes = await getContact(companyId, contactId);
    const takeCalls = defaultTo("No", contactRes.data.takeCalls);

    const statusFromAPI = getStatusFromApi(takeCalls);

    if (force || statusFromAPI !== status) {
      parseFromContact(contactRes.data);
    }
  };

  return {
    fetch,
    parseFromContact,
    actions,
    status,
    setStatus,
    transferTo,
    setTransferTo,
    setStatusExpireAtUTC,
    statusExpireAtUTC,
    // Advanced
    connectOnly,
    doNotConnect,
    currentUnavailabilityExplanation,
    currentUnavailabilityExplanationOther,
    currentStatusNote,
    overrideSubscriptionPagePermissions,
    setConnectOnly,
    setDoNotConnect,
    setCurrentUnavailabilityExplanation,
    setCurrentUnavailabilityExplanationOther,
    setCurrentStatusNote,
    //
    callStatusTemplateId,
    setCallStatusTemplateId,
    // Popover states
    isOpen,
    isOpenExternal,
    onOpen,
    onOpenExternal,
    onClose,
  };
};
