import Cookies from "js-cookie";

const cookieScope = process.env.REACT_APP_ENV as string;

const resolveKey = (key: string) => `${key}-${cookieScope}`;

const getRootDomain = (): string => {
  const hostname = window.location.hostname;
  // Check for IP addresses or localhost (no subdomains)
  if (hostname === "localhost" || /^[0-9.]+$/.test(hostname)) {
    return hostname;
  }
  const parts = hostname.split(".");
  // If it's a two-part domain (example.com), return it directly
  if (parts.length <= 2) {
    return hostname;
  }
  // If it's a subdomain, return only the last two parts (root domain)
  return parts.slice(-2).join(".");
};

const rootDomain = `.${getRootDomain()}`;

export const cookieStorage = {
  getItem: (key: string): Promise<string | null> => {
    const value = Cookies.get(resolveKey(key));
    return Promise.resolve(value || null);
  },

  getItemSync: (key: string): string | null => {
    return Cookies.get(resolveKey(key)) || null;
  },

  setItem: (key: string, value: string): Promise<void> => {
    Cookies.set(resolveKey(key), value, {
      domain: rootDomain,
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    return Promise.resolve();
  },

  setItemSync: (key: string, value: string): void => {
    Cookies.set(resolveKey(key), value, {
      domain: rootDomain,
      expires: 365,
      sameSite: "None",
      secure: true,
    });
  },

  removeItem: (key: string): Promise<void> => {
    Cookies.remove(resolveKey(key), { domain: rootDomain, path: "/" });
    return Promise.resolve();
  },

  removeItemSync: (key: string): void => {
    Cookies.remove(resolveKey(key), { domain: rootDomain, path: "/" });
  },
};
