import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { userAccountReducer } from "src/store/userAccountState";
import { companySelectionReducer } from "src/store/companySelectionState";
import { activityStateReducer } from "./activityState";
import { invoiceStateReducer } from "./invoiceState";
import { accountSettingReducer } from "./accountSettingState";
import { searchHistoryReducer } from "./searchHistoryState";
import { userStatusReducer } from "./userStatusState";
import { loggedInAsReducer } from "./loggedInAs";
import { appVersionReducer } from "./appVersion";

import { persistReducer, persistStore } from "redux-persist";
import { cookieStorage } from "../utils/cookieStorage";

const rootReducer = combineReducers({
  userAccount: userAccountReducer,
  companySelection: companySelectionReducer,
  activityState: activityStateReducer,
  invoiceState: invoiceStateReducer,
  accountSetting: accountSettingReducer,
  searchHistoryState: searchHistoryReducer,
  userStatus: userStatusReducer,
  loggedInAs: loggedInAsReducer,
  appVersion: appVersionReducer,
});

const persistConfig = {
  key: "root",
  storage: cookieStorage,
  whitelist: ["companySelection", "userAccount", "searchHistoryState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistedStore = persistStore(store);

export default store;
