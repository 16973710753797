import { useCompanySelection } from "src/store/companySelectionState";
import useUserAgreement from "src/hooks/mutations/useUserAgreement";
import { useLoggedInAs } from "../store/loggedInAs";
import { logoutUser } from "./mutations/useLogoutMutation";
import useUserAccount from "../store/userAccountState";
import useIsBetaOrigin from "./useIsBetaOrigin";
import { cookieStorage } from "../utils/cookieStorage";

const useRedirectBeta = () => {
  const { activeCompany: currentActiveCompanyId } = useCompanySelection();
  const { token: currentToken } = useLoggedInAs();
  const { mutate: mutateIsBetaUser } = useUserAgreement();
  const {
    impersonatedBy: currentImpersonatedBy,
    setToken,
    setIsAuthenticating,
  } = useLoggedInAs();
  const { userAccount } = useUserAccount();
  const isBetaOrigin = useIsBetaOrigin();
  const currentPathWithSearchParams =
    window.location.pathname + window.location.search;

  const prevUserId = userAccount?.id || "";

  const redirectBeta = (
    directionProdToBeta = true,
    markUserBetaState = true,
    token: string | null = null,
    companyId: string | null = null,
    path: string | null = null,
    impersonatedBy: string | null | false = null
  ) => {
    setIsAuthenticating(true);

    const baseUrl = directionProdToBeta
      ? (process.env.REACT_APP_BETA_BASE_URL as string) ||
        "https://beta.abby.com/"
      : (process.env.REACT_APP_PROD_BASE_URL as string) ||
        "https://my.abby.com/";

    console.debug("UseRedirectBeta: args", {
      directionProdToBeta,
      markUserBetaState,
      token,
      companyId,
      path,
      impersonatedBy,
    });

    const params = new URLSearchParams({
      token: token || currentToken || "",
      activeCompanyId: companyId || currentActiveCompanyId || "",
      path: path || currentPathWithSearchParams || "",
    });

    let destinationUrl = `${baseUrl}?${params.toString()}`;
    if (impersonatedBy !== false && (impersonatedBy || currentImpersonatedBy)) {
      destinationUrl += `&impersonatedBy=${
        impersonatedBy || currentImpersonatedBy
      }`;
    }

    if (markUserBetaState) {
      console.debug(
        `UseRedirectBeta: Setting isBetaUser=${directionProdToBeta}`
      );
      mutateIsBetaUser(
        {
          isBetaUser: directionProdToBeta,
        },
        {
          onSuccess: async () => {
            setTimeout(() => {
              logoutUser(prevUserId);
              setToken(null);
              cookieStorage.removeItemSync("entry-point-uri");
            }, 0);
            console.debug(
              `UseRedirectBeta: Redirect ${directionProdToBeta} after isBetaUser changed`,
              destinationUrl
            );
            window.location.href = destinationUrl;
          },
        }
      );
    } else {
      console.debug(
        `UseRedirectBeta: Straight redirect ${directionProdToBeta}`,
        destinationUrl
      );
      window.location.href = destinationUrl;
    }
  };

  const getExpectedBetaRedirectDirection = (isBetaUser: boolean) => {
    const val =
      isBetaUser && !isBetaOrigin
        ? "beta"
        : !isBetaUser && isBetaOrigin
        ? "prod"
        : null;
    console.debug("UseRedirectBeta: getExpectedBetaRedirectDirection", {
      isBetaUser,
      isBetaOrigin,
      result: val,
    });
    return val;
  };

  return {
    redirectBeta,
    getExpectedBetaRedirectDirection,
  };
};

export default useRedirectBeta;
